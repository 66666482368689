import React from 'react';
import '../App.scss';

class Error extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="Error" >
                {this.props.error}
            </div>
        )
    }
}

export default Error;
