import React from 'react';
import '../../App.scss';
import './QuizVert.scss';

class CardReponseVert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        if (this.props.isPortrait) {
            return (
                <div className="QuizVertReponses">
                    <div className="QuizVertReponsesContainer">
                        <div className="QuizVertCardReponse">
                            <div className={'QuizVertCardStat' + (this.props.partieToDisplay > 1 ? ' DragDone' : '')}>{this.props.partieToDisplay > 1 ? this.props.card.rate : ''}</div>
                            <div className="QuizVertCardTitle">{this.props.card.family}</div>
                            <div className="QuizVertCardReponseList" dangerouslySetInnerHTML={{ __html: this.props.card.content }}></div>
                            <div className="QuizVertCardDescription" dangerouslySetInnerHTML={{ __html: (this.props.partieToDisplay === 3 ? this.props.card.behaviors : '') }}></div>
                        </div >
                    </div>
                </div>
            );
        } else {
            return (
                <div className="QuizVertReponses">
                    <div className="QuizVertReponsesContainer">
                        {this.props.cards.map((card) => {
                            return (<div className="QuizVertCardReponse">
                                <div className={'QuizVertCardStat' + (this.props.partieToDisplay > 1 ? ' DragDone' : '')}>{this.props.partieToDisplay > 1 ? card.rate : ''}</div>
                                <div className="QuizVertCardTitle">{card.family}</div>
                                <div className="QuizVertCardReponseList" dangerouslySetInnerHTML={{ __html: card.content }}></div>
                                <div className="QuizVertCardDescription" dangerouslySetInnerHTML={{ __html: (this.props.partieToDisplay === 3 ? card.behaviors : '') }}></div>
                            </div >)
                        })}
                    </div>
                </div>
            );
        }

    };

}

export default CardReponseVert;
