import React from 'react';
import '../../App.scss';

class CardReponseOrange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="QuizOrangeContainer">
                <div className="QuizOrangeTitre">
                    {this.props.subtitle}
                </div>
                <div className="QuizOrangeReponseContainer">
                    <div className="QuizOrangeReponse DragDone">
                        {this.props.sentence.map(r => {
                            return (<span>{r}&nbsp;</span>)
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default CardReponseOrange;
