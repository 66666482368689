import React from 'react';
import './App.scss';
import Accueil from './commons/Accueil';
import End from './commons/End';
import Resultat from './commons/Resultat';
import Error from './commons/Error';
import QuizBleu from './quiz/quiz-bleu/QuizBleu';
import QuizOrange from './quiz/quiz-orange/QuizOrange';
import QuizRose from './quiz/quiz-rose/QuizRose';
import QuizVert from './quiz/quiz-vert/QuizVert';
import QuizViolet from './quiz/quiz-violet/QuizViolet';


import './ie.scss';


class App extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            version: {},
            wordings: {},
            data: {},
            etat: 1,
            success: true,
            resultat: false,
            resultatQuizMulti: [],
            isPortrait: false,
            error: ''
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.mobileHeight();

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
        document.ontouchmove = function (event) {
            event.preventDefault();
        }
    }

    mobileHeight() {
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handlePageChange(resultat, arrayReponse) {
        if (resultat !== '' || arrayReponse) {
            this.setState({ resultat: resultat, resultatQuizMulti: arrayReponse });
        }
        this.checkFormat();
    }

    checkUser() {
        if (!window.localStorage.getItem('userId')) {
            this.generateUserId();
        }
    }

    generateUserId() {
        window.localStorage.setItem('userId', Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2));
    }

    componentDidMount() {
        this.checkUser();
        const search = window.location.search;
        const params = search.split('=');
        if (!params || params[0] !== '?uid') {
            this.setState({ error: 'error' })
        }
        fetch(process.env.REACT_APP_API_URL + 'service/getData/' + params[1])
            .then(response => response.json())
            .then(json => {
                this.setState({
                    version: json.data.version,
                    wordings: json.data.wordings,
                    data: json.data.data,
                    error: json.data.errorMessage,
                    success: json.success,
                    etat: window.location.hash ? +window.location.hash.substring(1) : 1
                });
            }).catch(err => {
                this.setState({ error: err })
            })
    }

    checkFormat() {
        let newPage;
        this.state.isPortrait = window.innerHeight > window.innerWidth
        if (!this.state.isPortrait & ([4, 12, 20].includes(this.state.etat))) {
            newPage = this.state.etat + 6;
        } else if (!this.state.isPortrait & this.state.etat === 51) {
            newPage = this.state.etat + 4;
        } else {
            newPage = this.state.etat + 1;
        }
        window.location.hash = '#' + newPage;
        this.setState({ etat: newPage });
    }

    displayPage() {
        this.state.isPortrait = window.innerHeight > window.innerWidth

        if (this.state.error) {
            return <Error error={this.state.error}></Error>
        } else {
            switch (this.state.etat) {
                //Accueil
                case 1: return <Accueil onPageChange={this.handlePageChange} wordings={this.state.wordings} version={this.state.version} />;

                //Quiz Vert
                case 2: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={1} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 3: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={1} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 4: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[0]} resultat={this.state.resultatQuizMulti[0]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 5: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[1]} resultat={this.state.resultatQuizMulti[1]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 6: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[2]} resultat={this.state.resultatQuizMulti[2]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 7: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[3]} resultat={this.state.resultatQuizMulti[3]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 8: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[4]} resultat={this.state.resultatQuizMulti[4]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 9: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={1} cardToDisplay={this.state.data.mode_1.cards[5]} resultat={this.state.resultatQuizMulti[5]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                case 10: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={2} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 11: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={2} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 12: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[0]} resultat={this.state.resultatQuizMulti[0]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 13: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[1]} resultat={this.state.resultatQuizMulti[1]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 14: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[2]} resultat={this.state.resultatQuizMulti[2]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 15: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[3]} resultat={this.state.resultatQuizMulti[3]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 16: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[4]} resultat={this.state.resultatQuizMulti[4]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 17: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={2} cardToDisplay={this.state.data.mode_1.cards[5]} resultat={this.state.resultatQuizMulti[5]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                case 18: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={3} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 19: return <QuizVert onPageChange={this.handlePageChange} partieToDisplay={3} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_1} />;
                case 20: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[0]} resultat={this.state.resultatQuizMulti[0]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 21: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[1]} resultat={this.state.resultatQuizMulti[1]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 22: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[2]} resultat={this.state.resultatQuizMulti[2]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 23: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[3]} resultat={this.state.resultatQuizMulti[3]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 24: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[4]} resultat={this.state.resultatQuizMulti[4]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 25: return <Resultat data={this.state.data.mode_1} isPortrait={this.state.isPortrait} color={'Vert'} partieToDisplay={3} cardToDisplay={this.state.data.mode_1.cards[5]} resultat={this.state.resultatQuizMulti[5]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                //Quiz Rose
                case 26: return <QuizRose onPageChange={this.handlePageChange} questionToDisplay={1} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_2} />;
                case 27: return <QuizRose onPageChange={this.handlePageChange} questionToDisplay={1} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_2} />;
                case 28: return <Resultat isPortrait={this.state.isPortrait} color={'Rose'} data={this.state.data.mode_2} questionToDisplay={1} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 29: return <QuizRose onPageChange={this.handlePageChange} questionToDisplay={2} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_2} />;
                case 30: return <Resultat isPortrait={this.state.isPortrait} color={'Rose'} data={this.state.data.mode_2} questionToDisplay={2} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 31: return <QuizRose onPageChange={this.handlePageChange} questionToDisplay={3} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_2} />;
                case 32: return <Resultat isPortrait={this.state.isPortrait} color={'Rose'} data={this.state.data.mode_2} questionToDisplay={3} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                //Quiz Orange
                case 33: return <QuizOrange onPageChange={this.handlePageChange} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_3} />;
                case 34: return <QuizOrange onPageChange={this.handlePageChange} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_3} />;
                case 35: return <Resultat isPortrait={this.state.isPortrait} color={'Orange'} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} data={this.state.data.mode_3} />

                //Quiz Bleu
                case 36: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={1} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 37: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={1} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 38: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[0]} questionNumber={1} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 39: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={2} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 40: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[1]} questionNumber={2} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 41: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={3} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 42: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[2]} questionNumber={3} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 43: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={4} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 44: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[3]} questionNumber={4} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 45: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={5} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 46: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[4]} questionNumber={5} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 47: return <QuizBleu onPageChange={this.handlePageChange} questionNumber={6} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_4} />;
                case 48: return <Resultat isPortrait={this.state.isPortrait} color={'Bleu'} data={this.state.data.mode_4.questions[5]} questionNumber={6} resultat={this.state.resultat} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                //Quiz Violet
                case 49: return <QuizViolet onPageChange={this.handlePageChange} showPopUp={true} wordings={this.state.wordings} data={this.state.data.mode_5} />;
                case 50: return <QuizViolet onPageChange={this.handlePageChange} showPopUp={false} wordings={this.state.wordings} data={this.state.data.mode_5} />;
                case 51: return <Resultat isPortrait={this.state.isPortrait} color={'Violet'} data={this.state.data.mode_5} cardToDisplay={this.state.data.mode_5.cards[0]} resultat={this.state.resultatQuizMulti[0]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 52: return <Resultat isPortrait={this.state.isPortrait} color={'Violet'} data={this.state.data.mode_5} cardToDisplay={this.state.data.mode_5.cards[1]} resultat={this.state.resultatQuizMulti[1]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 53: return <Resultat isPortrait={this.state.isPortrait} color={'Violet'} data={this.state.data.mode_5} cardToDisplay={this.state.data.mode_5.cards[2]} resultat={this.state.resultatQuizMulti[2]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />
                case 54: return <Resultat isPortrait={this.state.isPortrait} color={'Violet'} data={this.state.data.mode_5} cardToDisplay={this.state.data.mode_5.cards[3]} resultat={this.state.resultatQuizMulti[3]} resultatQuizMulti={this.state.resultatQuizMulti} onPageChange={this.handlePageChange} wordings={this.state.wordings} />

                //Fin
                case 55: return <End onPageChange={this.handlePageChange} wordings={this.state.wordings} version={this.state.version} />

                //Accueil par défaut
                default: return <Accueil onPageChange={this.handlePageChange} wordings={this.state.wordings} version={this.state.version} />;
            }
        }
    }

    render() {
        return (
            <div className="Sensikit">
                {this.displayPage()}
            </div>
        );
    }
}

export default App;
