import React from 'react';
import '../App.scss';
import faux from '../assets/images/Pouce-Bas.png';
import vrai from '../assets/images/Pouce-Haut.png';
import CardReponseBleu from '../quiz/quiz-bleu/CardReponseBleu';
import CardReponseOrange from '../quiz/quiz-orange/CardReponseOrange';
import CardReponseRose from '../quiz/quiz-rose/CardReponseRose';
import CardReponseVert from '../quiz/quiz-vert/CardReponseVert';
import CardReponseViolet from '../quiz/quiz-violet/CardReponseViolet';
import './Resultat.scss';

class Resultat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onPageChange("", "");
    }

    componentDidMount() {
        if (this.props.color === 'Vert') {
            for (let i = 1; i < 7; i++) {
                this.saveResultat(i + 6 * (this.props.partieToDisplay - 1), this.props.resultatQuizMulti[i - 1]);
            }
        } else if (this.props.color === 'Violet') {
            for (let i = 1; i < 5; i++) {
                this.saveResultat(i, this.props.resultatQuizMulti[i - 1]);
            }
        } else {
            this.saveResultat('', this.props.resultat);
        }
    }

    saveResultat(itemPaysage, reponse) {
        const search = window.location.search;
        const params = search.split('=');
        if (!params || params[0] !== '?uid') {
            this.setState({ error: 'error' })
        }
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'userUid': window.localStorage.getItem('userId'),
                'companyUid': params[1],
                'mode': this.props.color === 'Vert' ? 1 : this.props.color === 'Rose' ? 2 : this.props.color === 'Orange' ? 3 : this.props.color === 'Bleu' ? 4 : 5,
                'item': itemPaysage ? itemPaysage : this.props.color === 'Vert' ? this.props.cardToDisplay.id : this.props.color === 'Rose' ? this.props.questionToDisplay : this.props.color === 'Orange' ? 1 : this.props.color === 'Bleu' ? this.props.questionNumber : this.props.cardToDisplay.id,
                'result': reponse ? 1 : 0
            })
        };
        fetch(process.env.REACT_APP_API_URL + 'service/saveResult/', requestOptions)
    }

    render() {
        let checkResult = !this.props.isPortrait && (this.props.color === 'Vert' || this.props.color === 'Violet') ? !this.props.resultatQuizMulti.includes(false) : this.props.resultat;

        let response;
        switch (this.props.color) {
            case 'Vert': response = <CardReponseVert isPortrait={this.props.isPortrait} cards={this.props.data.cards} partieToDisplay={this.props.partieToDisplay} card={this.props.cardToDisplay} key={this.props.cardToDisplay.id}></CardReponseVert>; break;
            case 'Rose': response = <CardReponseRose disabled={true} questionToDisplay={this.props.data.questions[this.props.questionToDisplay - 1]}></CardReponseRose>; break;
            case 'Orange': response = <CardReponseOrange subtitle={this.props.data.subtitle} sentence={this.props.data.sentenceParts}></CardReponseOrange>; break;
            case 'Bleu': response = <CardReponseBleu disabled={true} questionNumber={this.props.questionNumber} questionToDisplay={this.props.data}></CardReponseBleu>; break;
            case 'Violet': response = <CardReponseViolet isPortrait={this.props.isPortrait} cards={this.props.data.cards} card={this.props.cardToDisplay}></CardReponseViolet>; break;
            //vert par défault
            default: response = <CardReponseVert partieToDisplay={this.props.partieToDisplay} card={this.props.cardToDisplay} key={this.props.cardToDisplay.id}></CardReponseVert>; break;
        }
        // Affichage bonne réponse
        return (
            <div className="ResultatContainer">
                <div className={'BgReponse ' + (checkResult ? 'FondCorrect' : 'FondFaux')}>
                    <div className={checkResult ? 'FondCorrect' : 'FondFaux'}>
                    </div>
                    <div className="BgBlanc">
                    </div>
                    <div className="BgBlancCasse">
                        <img src={checkResult ? vrai : faux} className="Pouce" alt="pouce" />
                    </div>
                    <div className="BgBlanc">
                    </div>
                    <div className={'Blanc ReponseBas' + (checkResult ? 'FondCorrect' : 'FondFaux')}>
                        <div className="Bravo">
                            {checkResult ? this.props.wordings["ui.results.win"] : this.props.wordings["ui.results.loose"]}
                        </div>
                        <div className={checkResult ? 'FakeHeight' : 'Show BonneReponse '}>
                            {checkResult ? '' : this.props.wordings["ui.results.rightAnswer"]}
                        </div>
                        {response}
                    </div>
                </div>
                <button className={'BoutonSuivant ' + (checkResult ? 'SuivantJuste' : 'SuivantFaux')} onClick={() => this.handleChange()}>
                    {this.props.wordings["ui.btn.next"]}
                </button>
            </div>

        )
    }
}

export default Resultat;
