import React from 'react';
import '../App.scss';

class Accueil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onPageChange("", "");
    }

    render() {
        const logoEntrepriseSyle = {
            backgroundImage: 'url(' + this.props.version.logo + ')',
        };
        // Affichage Page accueil
        return (
            <div className="Splashscreen">
                <div className="LogoContainer Left">
                    <div className="LogoEntreprise Left" style={logoEntrepriseSyle}></div>
                </div>
                <div className="BandesContainer Rayures"></div>
                <div className="SplashscreenFooter">
                    <button onClick={() => this.handleChange()}>
                        <i className="StartButton"></i>
                        {this.props.wordings['ui.btn.start']}
                    </button>
                </div>
            </div>
        )
    }
}

export default Accueil;
