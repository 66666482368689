import React from 'react';
import '../App.scss';
import './PopUp.scss';
import vert from '../assets/images/Rond-Vert.png';
import rose from '../assets/images/Rond-Rose.png';
import bleu from '../assets/images/Rond-Bleu.png';
import violet from '../assets/images/Rond-Violet.png';
import orange from '../assets/images/Rond-Orange.png';

class PopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onPageChange("", "");
    }

    render() {
        //Affichage des popups
        return (
            <div className="PopupContainer" >
                <div className={'Popup Popup' + this.props.color} >
                    <div className={'BtnInfo BtnInfo' + this.props.color}>
                        {this.props.infos}
                    </div>
                    {this.props.titre ? <div className={'TitrePopup TitrePopup' + this.props.color}>
                        {this.props.titre}
                    </div> : null}
                    <div className={'TextPopup TextPopup' + this.props.color}>
                        {this.props.texte}
                    </div>
                    <button className={'BtnOk BtnOk' + this.props.color} onClick={() => this.handleChange()}>
                        {this.props.ok}
                    </button>
                </div>
                <div className='Ronds'>
                    <img className={'Rond ' + (this.props.color === 'Vert' ? 'RondGros' : 'RondPetit')} src={vert} alt='vert' />
                    <img className={'Rond ' + (this.props.color === 'Rose' ? 'RondGros' : 'RondPetit')} src={rose} alt='rose' />
                    <img className={'Rond ' + (this.props.color === 'Orange' ? 'RondGros' : 'RondPetit')} src={orange} alt='orange' />
                    <img className={'Rond ' + (this.props.color === 'Bleu' ? 'RondGros' : 'RondPetit')} src={bleu} alt='bleu'/>
                    <img className={'Rond ' + (this.props.color === 'Violet' ? 'RondGros' : 'RondPetit')} src={violet} alt='violet'/>
                </div>
            </div>
        );
    }
}

export default PopUp;
