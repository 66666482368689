
import { isMobileSafari, isSafari } from 'react-device-detect';

const classExceptions = ['', 'QuizVertCardImageTitle', 'CardDrag', 'indexImage'];
const classSources = ['CardGrise', 'CardVioletGrise', 'QuizVertCards', 'QuizVioletCards', 'QuizVioletCard',
    'QuizVertCardImageTitle', 'QuizVertCard1', 'QuizVertCard2', 'QuizVertCard3', 'indexImage'];

export const changeTouch = (event, id, callback) => {
    var changedTouch = event.changedTouches[0];
    var source = event.currentTarget;

    while (!source.className || classExceptions.includes(source.className)) {
        source = source.parentElement;
    }

    removeClones();
    var dest = document.elementFromPoint(changedTouch.pageX, changedTouch.pageY);

    if (dest) {
        while ((!dest.id || dest.id.indexOf('dropCard') === -1) && dest.parentElement) { dest = dest.parentElement }
    }

    if (dest) {
        callback(id, dest.id.substring(dest.id.lastIndexOf('-') + 1), true, (!dest.id || dest === source || isTargetSameAsSource(dest)));
    }
}

export const shuffle = function (originalArray) {
    var array = [...originalArray]
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

export const prepareElementsForDrag = function (ev, id) {
    if (ev.dataTransfer) {
        var el = document.getElementById('Card-' + id);
        var clone;
        if (el) {
            console.log('3')
            if (!isSafari || isMobileSafari) {
                clone = el.cloneNode(true);
                clone.classList.add('Dragging');
                document.body.appendChild(clone);
                ev.dataTransfer.setDragImage(clone, 50, 50);
            }
        }
        ev.dataTransfer.setData("text", id.toString())
    } else {
        ev.currentTarget.id = 'Card-' + id;
    }
}

export const enableDragZones = function (zones) {
    var dropZones = document.getElementsByClassName(zones);
    for (let d of dropZones) d.classList.add('DragActive');
}

export const disableDragZones = function (zones) {
    var dropZones = document.getElementsByClassName(zones);
    for (let d of dropZones) d.classList.remove('DragActive');
    removeClones();
}

export const cancelDragging = function () {
    var dragElements = document.getElementsByClassName('Dragging');
    for (let d of dragElements) d.classList.remove('Dragging');
}

export const cloneElementToDrag = function (source, id) {
    var clone = source.cloneNode(true);
    clone.classList.add('Dragging');
    var elem = document.getElementById('Copy-' + id);
    if (elem) {
        elem.appendChild(clone);
        elem.style.zIndex = -1;
    }

    return elem;
}

export const removeClones = function () {
    var nodes = document.getElementsByClassName('Dragging');
    for (let n of nodes) n.remove();
}

export const isTargetSameAsSource = function (dest) {
    if (dest.className) {
        for (let c of classSources) {
            let classes = dest.className.split(' ');
            for (let cl of classes) {
                if (c === cl) {
                    console.log(c, cl);
                    return true;
                }
            }
        }
    }
    return false;
}

export const height = function(elem){
    return elem.innerHeight ? elem.innerHeight : elem.scrollHeight;
}

export const width = function(elem){
    return elem.innerWidth ? elem.innerWidth : elem.scrollWidth;
}

if ('function' !== typeof DataTransfer.prototype.setDragImage) {
    DataTransfer.prototype.setDragImage = function (image, offsetX, offsetY) { };
}