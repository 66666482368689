import React from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import '../../App.scss';
import ExternalLink from '../../assets/images/ExternalLink.png';
import FlecheDroite from '../../assets/images/Fleche-Droite.png';
import FlecheGauche from '../../assets/images/Fleche-Gauche.png';
import { changeTouch, cloneElementToDrag, disableDragZones, enableDragZones, prepareElementsForDrag, removeClones, shuffle, height, width } from '../../commons/Utils';
import '../../quiz/quiz-vert/QuizVert.scss';

class DragAndDropVert extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            cardResponse: ["", "", "", "", "", ""],
            position: 0,
            shuffledCards: [],
            dragInfos: [0, 0, 0, 'none']
        }

        this.state.shuffledCards = shuffle(this.props.data.cards);
    }

    prev() {
        this.setState({ position: (this.state.position + this.props.data.cards.length - 1) % this.props.data.cards.length });
    }

    next() {
        this.setState({ position: (this.state.position + 1) % this.props.data.cards.length });
    }

    onTouchStart = (ev, id) => {
        enableDragZones('QuizVertCardReponse');

        var source = ev.target;
        while (source.className.indexOf('QuizVertCard ') === -1) {
            source = source.parentElement;
        }
        cloneElementToDrag(source, id);

        this.setState({
            dragInfos: [
                id,
                0,
                0,
                'none'
            ]
        });

        this.onDragStart(ev, id);
    }

    onDragStart = (ev, id) => {
        enableDragZones('QuizVertCardReponse');
        prepareElementsForDrag(ev, id);
    }

    onDragOver = (ev, id) => {
        if (ev && ev.changedTouches) {
            var elem = document.getElementById('Copy-' + id);
            if (elem) {
                elem.style.display = 'flex';
            }
            var changedTouch = ev.changedTouches[0];
            this.setState({
                dragInfos: [
                    id,
                    changedTouch.clientX - width(elem),
                    changedTouch.clientY - height(elem) * 0.5,
                    'flex'
                ]
            });
        } else {
            ev.preventDefault();
        }
    }

    onDrop = (ev, index) => {
        let id;
        let takeIndex = false;
        if (ev.dataTransfer) {
            id = ev.dataTransfer.getData("text");
            takeIndex = true
        } else {
            id = ev.currentTarget.id;
        }

        this.drop(id, index, takeIndex);
        ev.preventDefault();

    }

    drop = (id, index, takeIndex, invalidDrop) => {
        if (!invalidDrop) {
            let position = takeIndex ? index : this.state.position;
            let testReponse = [];
            let questions = this.props.data.cards.filter((q) => {
                if (q.id.toString() === id.toString()) {
                    this.state.cardResponse[position] = q.id + '-' + (this.props.partieToDisplay === 1 ? q.family : this.props.partieToDisplay === 2 ? q.rate : q.behaviors);
                    testReponse = this.state.cardResponse.map(e => e !== "");
                    if (!testReponse.includes(false)) {
                        this.props.onReadyChange(true, this.state.cardResponse.map(e => e.substring(2)));
                    }
                }
                return q;
            });

            this.setState({
                ...this.state,
                questions,
                dragInfos: [0, 0, 0, 'none']
            });
        }

        var elem = document.getElementById('Copy-' + id);
        if (elem) {
            elem.innerHTML = '';
        }

        this.setState({
            dragInfos: [0, 0, 0, 'none']
        });

        removeClones();
    }

    reset(answerPosition) {
        var newReponses = [...this.state.cardResponse];
        newReponses[answerPosition] = '';
        this.setState({
            cardResponse: newReponses
        });
        this.props.onReadyChange(false, '');
    }

    render() {
        const typePartie = this.props.partieToDisplay;
        const listToDisplay = this.props.partieToDisplay === 1 ? 'family' : this.props.partieToDisplay === 2 ? 'rate' : 'behaviors';
        return (
            <div classfamily="container-drag">
                <div className="QuizVertTitre">
                    {this.props.data['subtitle_' + this.props.partieToDisplay]}
                </div>

                <div className={'QuizVertCards' + (isSafari && !isMobileSafari ? ' Safari' : '')}>
                    {this.state.shuffledCards.map((card, index) => {
                        const valueToCompare = card.id + '-' + (this.props.partieToDisplay === 1 ? card.family : this.props.partieToDisplay === 2 ? card.rate : card.behaviors);
                        const draggable = this.state.cardResponse.includes(valueToCompare) ? false : true;
                        const copyStyle = {
                            position: 'absolute', zIndex: 99, display: this.state.dragInfos[3], top: this.state.dragInfos[2], left: this.state.dragInfos[1]
                        }
                        const cardImage = {
                            backgroundImage: 'url("' + card.familyPicto + '")'
                        }
                        const msCardStyle = {
                            msGridColumn: (index % (this.state.shuffledCards.length / 2) + 1), msGridRow: index < (this.state.shuffledCards.length / 2) ? 1 : 2
                        }
                        return <div key={card.id}>
                            <div className={this.state.cardResponse.includes(valueToCompare) ? 'CardGrise' : ('QuizVertCard ' + 'QuizVertCard' + typePartie)}
                                id={'Card-' + card.id}
                                onDragStart={(e) => draggable ? this.onDragStart(e, card.id) : null}
                                onDragEnd={(e) => disableDragZones('QuizVertCardReponse')}
                                onTouchStart={(e) => draggable ? this.onTouchStart(e, card.id) : null}
                                onTouchMove={(e) => draggable ? this.onDragOver(e, card.id) : null}
                                onTouchEnd={(e) => { if (draggable) { disableDragZones('QuizVertCardReponse'); changeTouch(e, card.id, this.drop) } }}
                                draggable={draggable} classfamily="draggable" style={msCardStyle}>
                                {listToDisplay === 'family' ? <div className={this.state.cardResponse.includes(valueToCompare) ? 'Hide' : 'QuizVertCardImage'} style={cardImage}></div> : null}
                                <div className={this.state.cardResponse.includes(valueToCompare) ? 'Hide' : 'QuizVertCardImageTitle'}
                                    dangerouslySetInnerHTML={{ __html: listToDisplay !== 'behaviors' ? card[listToDisplay] : card.behaviors }}></div>
                                <div className={this.state.cardResponse.includes(valueToCompare) ? 'Hide' : 'CardDrag'}><img className="indexImage" draggable="false" src={ExternalLink} alt='card' /></div>
                            </div>
                            <div id={'Copy-' + card.id} style={this.state.dragInfos[0] === card.id ? copyStyle : null}></div>
                        </div>
                    })}
                </div>

                <div className="QuizSeparator"></div>

                <div className="QuizVertReponses">
                    <div className="ReponsePrevContainer">
                        <button className="ReponsePrev" onClick={() => this.prev()}>
                            <img src={FlecheGauche} alt='gauche' />
                        </button>
                    </div>
                    <div className="QuizVertReponsesContainer">
                        {this.props.data.cards.map((card, index) => {
                            const cardStyle = {
                                marginLeft: this.state.position === 0 || index > 0 ? '0' : (-1 * (this.state.position) * 100) + '%',
                            };
                            return (<div className={'QuizVertCardReponse' + ' QuizVert' + typePartie} id={'dropCard-' + index} key={'reponse-' + index} style={cardStyle}
                                onDragOver={(e) => this.onDragOver(e, index)}
                                onDrop={(e) => this.onDrop(e, index)}>
                                <div className={'QuizVertCardStat' + (typePartie === 2 ? (!this.state.cardResponse[index].substring(2) ? ' DragActive' : ' DragDone') : (typePartie === 3 ? ' DragGreen' : ' '))} classfamily={typePartie === 2 ? 'droppable' : ''} onClick={(e) => this.reset(index)}>
                                    {typePartie > 2 ? card.rate : (typePartie === 2 ? (this.state.cardResponse[index].substring(2) ? this.state.cardResponse[index].substring(2) : <span>+</span>) : null)}</div>
                                <div className={'QuizVertCardTitle' + (typePartie === 1 ? (!this.state.cardResponse[index].substring(2) ? ' DragActive' : ' DragDone') : ' ')} classfamily={typePartie === 1 ? 'droppable' : ''} onClick={(e) => this.reset(index)}
                                >{typePartie > 1 ? card.family : (this.state.cardResponse[index].substring(2) && typePartie === 1 ? this.state.cardResponse[index].substring(2) : <span>+</span>)}</div>
                                <div className="QuizVertCardReponseList" dangerouslySetInnerHTML={{ __html: card.content }}></div>
                                <div className={'QuizVertCardDescription' + (typePartie === 3 ? (!this.state.cardResponse[index].substring(2) ? ' DragActive' : ' DragDone') : ' ')} classfamily={typePartie === 3 ? 'droppable' : ''} onClick={(e) => this.reset(index)}
                                    dangerouslySetInnerHTML={{ __html: (typePartie !== 3 ? '' : (this.state.cardResponse[index].substring(2) ? this.state.cardResponse[index].substring(2) : '<span>+</span>')) }}>
                                </div>
                            </div>);
                        })}
                    </div>
                    <div className="ReponseNextContainer">
                        <button className="ReponseNext" onClick={() => this.next()}>
                            <img src={FlecheDroite} alt='droite' />
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

export default DragAndDropVert;