import React from 'react';
import '../../App.scss';
import ExternalLink from '../../assets/images/ExternalLink.png';
import PopUp from '../../commons/PopUp';
import { changeTouch, cloneElementToDrag, prepareElementsForDrag, removeClones, shuffle } from '../../commons/Utils';
import './QuizOrange.scss';

class QuizOrange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardResponse: [],
            ready: false,
            shuffledSentences: [],
            dragInfos: [0, 0, 0, 0, 0]
        }
        this.state.shuffledSentences = shuffle(this.props.data.sentenceParts);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(r) {
        this.props.onPageChange(r, "");
    }

    checkAnswer() {
        this.handleChange(this.state.cardResponse.toString() === this.props.data.sentenceParts.toString())
    }

    resetAll() {
        this.setState({ cardResponse: [] })
    }

    onTouchStart = (ev, index) => {
        var changedTouch = ev.changedTouches[0];
        cloneElementToDrag(ev.target, index);

        this.setState({
            dragInfos: [
                index,
                0,
                0,
                changedTouch.clientX,
                changedTouch.clientY
            ]
        });

        this.onDragStart(ev, index);
    }

    onDragStart = (ev, id) => {
        document.getElementById('QuizOrangeDropZone').classList.add('DragActive');
        prepareElementsForDrag(ev, id);
    }

    onDragOver = (ev, id) => {
        if (ev && ev.changedTouches) {
            var elem = document.getElementById('Copy-' + id);
            if (elem) {
                elem.style.display = 'flex';
            }

            var changedTouch = ev.changedTouches[0];
            this.setState({
                dragInfos: [
                    id,
                    changedTouch.clientX - this.state.dragInfos[3],
                    changedTouch.clientY - this.state.dragInfos[4],
                    this.state.dragInfos[3],
                    this.state.dragInfos[4]
                ]
            });
        } else {
            ev.preventDefault();
        }
    }

    onDrop = (ev, index) => {
        let id;
        if (ev.dataTransfer) {
            id = ev.dataTransfer.getData("text");
        } else {
            id = ev.currentTarget.id;
        }
        this.drop(id, index);
        ev.preventDefault();
    }

    drop = (id) => {
        let questions = this.state.shuffledSentences.filter((q) => {
            if (q === this.state.shuffledSentences[id]) {
                this.state.cardResponse.push(q);
            }
            return q;
        });

        var elem = document.getElementById('Copy-' + id);
        if (elem) {
            elem.innerHTML = '';
        }

        this.setState({
            ...this.state,
            questions,
            dragInfos: [0, 0, 0, 0, 0]
        });

        removeClones();
    }

    render() {
        //Affichage PopUp + quizz en fond
        return (
            <div className="H-100">
                <div className={this.props.showPopUp ? 'Show' : 'Hide'}>
                    <PopUp onPageChange={this.handleChange}
                        color={'Orange'}
                        titre={this.props.data.title}
                        texte={this.props.data.desc}
                        infos={"1/1"}
                        ok={this.props.wordings["ui.btn.ok"]} />
                </div>
                <div className="QuizOrangeContainer">
                    <div className="QuizOrangeTitre">
                        {this.props.data.subtitle}
                    </div>
                    <div className="QuizOrangeReponseContainer">
                        <div id="QuizOrangeDropZone" className={'QuizOrangeReponse ' + (this.state.cardResponse.length === 0 ? 'DragNone' : (this.state.cardResponse.length === 5 ? 'DragDone' : ''))} classfamily="droppable"
                            onDragOver={(e) => this.onDragOver(e)}
                            onDrop={(e) => this.onDrop(e)}>
                            {this.state.cardResponse.map((r, index) => {
                                return (<span key={'reponse' + index}>{r}&nbsp;</span>)
                            })}
                        </div>
                    </div>
                    <div className="QuizSeparator"></div>
                    <div className="Sentences">
                        {this.state.shuffledSentences.map((sentence, index) => {
                            const draggable = this.state.cardResponse.includes(sentence) ? false : true;
                            const copyStyle = {
                                position: 'absolute', zIndex: 99, top: 0, left: 0, marginLeft: this.state.dragInfos[1], marginTop: this.state.dragInfos[2]
                            }

                            return (<div className={this.state.cardResponse.includes(sentence) ? 'HideCard' : 'QuizOrangeSentence'}
                                id={'Card-' + index} key={index}
                                onDragStart={(e) => draggable ? this.onDragStart(e, index) : null}
                                onDragEnd={(e) => { removeClones(); document.getElementById('QuizOrangeDropZone').classList.remove('DragActive') }}
                                onTouchStart={(e) => draggable ? this.onTouchStart(e, index) : null}
                                onTouchMove={(e) => draggable ? this.onDragOver(e, index) : null}
                                onTouchEnd={(e) => { if (draggable) { document.getElementById('QuizOrangeDropZone').classList.remove('DragActive'); changeTouch(e, index, this.drop) } }}
                                draggable={draggable} classfamily="draggable">
                                {sentence}
                                <div className="CardDrag"><img src={ExternalLink} alt='card1' /></div>
                                <div className="OrangeCopy" id={'Copy-' + index} style={this.state.dragInfos[0] === index ? copyStyle : null}></div>
                            </div>)
                        })}
                    </div>
                </div>
                <div className="BoutonsOrange">
                    <button className={'BoutonValider BoutonEffacer ' + (this.state.cardResponse.length > 0 ? 'Show' : 'Hide')} onClick={() => this.resetAll()}>
                        {this.props.wordings['ui.btn.reset']}
                    </button>
                    <button className={'BoutonValider BoutonOrangeValider ' + (this.state.cardResponse.length === 5 ? 'Show' : 'Hide')} onClick={() => this.checkAnswer()}>
                        {this.props.wordings['ui.tbn.validate']}
                    </button>
                </div>
                <div className="PageFooter PageOrangeFooter"></div>
            </div>
        )
    }
}

export default QuizOrange;
